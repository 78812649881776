import Modal from '../Modal'
import {PayRunModel, PayTransferState} from "../../redux/types";
import Link from '../Link';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCheck,
  faMoneyBill,
  faPause,
  faPlay,
  faUser,
  faUserClock
} from "@fortawesome/free-solid-svg-icons";
import {Colors} from "../../constants/colors";
import React, {useState} from "react";
import {createUseStyles} from "react-jss";
import {formatMoney} from "../../utils/moneyHelper";
import Badge from "react-bootstrap/Badge";
import PayoutAttemptList from "./PayoutAttemptList";
import Card from "../Card";
import {Toolbar, ToolbarItem} from "../Learning/Toolbar";
import CompletePayRunModal from "./CompletePayRunModal";
import usePayRunDashboardActions from "../../hooks/usePayRunDashboardActions";  
import {capitalize} from "../../utils/stringHelper";


interface Props {
  payRun: PayRunModel | undefined
  onCloseClick?: () => void
  visible: boolean
}

const PayRunModal = ({payRun, onCloseClick, visible}: Props) => {
  const styles = useStyles()

  const {pausePayRun, resumePayRun, completePayRun} = usePayRunDashboardActions()

  const [confirmModalVisible, setConfirmModalVisible] = useState(false)

  const handleOnClose = () => {
    onCloseClick && onCloseClick()
  }

  const onConfirmManualHandlingClick = (payRunId: string) => {
    setConfirmModalVisible(false)
    completePayRun(payRunId)
    handleOnClose()
  }

  if (!payRun) {
    return null
  }

  const userName = `${payRun.userGivenName} ${payRun.userFamilyName}`
  const title = `Pay run for ${userName}`

  return (
    <Modal
      show={visible}
      title={title}
      okButtonTitle='Close'
      hideCancelButton={true}
      onHide={() => handleOnClose()}
      onOkClick={() => handleOnClose()}
      onCancelClick={() => handleOnClose()}
      size={'lg'}>

      <div className={styles.modal}>

        <Toolbar>
          <ToolbarItem icon={faPause} title={"Pause"} display={"iconAndText"}
                       disabled={payRun.payTransferState !== PayTransferState.PayoutPending}
                       onClick={() => {
                         pausePayRun(payRun.id)
                         handleOnClose()
                       }}/>
          <ToolbarItem icon={faPlay} title={"Resume"} display={"iconAndText"}
                       disabled={payRun.payTransferState !== PayTransferState.Paused}
                       onClick={() => {
                         resumePayRun(payRun.id)
                         handleOnClose()
                       }}/>
          <ToolbarItem icon={faCheck} title={"Complete"} display={"iconAndText"}
                       disabled={payRun.payTransferState !== PayTransferState.Paused}
                       onClick={() => setConfirmModalVisible(true)}/>
        </Toolbar>

        <div className={styles.modalSection}>
          <Badge variant={"secondary"}>{`Id: ${payRun.id}`}</Badge>
          {payRun.type === 'RemainingDebt' && <Badge variant={"info"}>Remaining debt</Badge>}
          {payRun.frozenDueToSalaryDeficit && <Badge variant={"info"}>Frozen due to pay deficit</Badge>}
          {payRun.frozenDueToPendingPayouts && <Badge variant={"info"}>Frozen due to pending payouts</Badge>}
          {payRun.frozenDueToMissingInPayrollFile &&
              <Badge variant={"warning"}>Frozen due to missing in payroll file</Badge>}
        </div>

        <div className={styles.modalSection}>
          <Link to={`/users/${payRun.userId}`}>
            <div className={styles.linkContainer}>
              <FontAwesomeIcon icon={faUser} color={Colors.white}/>
              User: {userName}
            </div>
          </Link>

          <Link to={`/employees/${payRun.employeeId}`}>
            <div className={styles.linkContainer}>
              <FontAwesomeIcon icon={faUserClock} color={Colors.white}/>
              Employee: {userName}
            </div>
          </Link>

          <Link to={`/employers/${payRun.employerId}`}>
            <div className={styles.linkContainer}>
              <FontAwesomeIcon icon={faBuilding} color={Colors.white}/>
              Employer: {payRun.employerName}
            </div>
          </Link>

          <Link to={`/paydays/${payRun.payDayId}/payruns/${payRun.id}`}>
            <div className={styles.linkContainer}>
              <FontAwesomeIcon icon={faMoneyBill} color={Colors.white}/>
              Pay run details
            </div>
          </Link>
        </div>

        <div className={styles.modalSection}>
          <Card className={`${styles.card} card`}>
            <div className={styles.cardContent}>
              <h6>State</h6>
              <p>State: <span>{capitalize(payRun.state)}</span></p>
              <p>Pay transfer state: <span>{capitalize(payRun.payTransferState)}</span></p>
              <p>Debt transfer state: <span>{capitalize(payRun.debtTransferState)}</span></p>
              <p>Employer deduction state: <span>{capitalize(payRun.employerDeductionState)}</span></p>
            </div>
          </Card>

          <Card className={`${styles.card} card`}>
            <div className={styles.cardContent}>
              <h6>Payout details</h6>
              <p>Payout method: <span>{capitalize(payRun.payoutMethod.payoutMethodType)}</span></p>
              <p>Payout target: <span>{payRun.payoutMethod.target}</span></p>
            </div>
          </Card>

          <Card className={`${styles.card} card`}>
            <div className={styles.cardContent}>
              <h6>Pay details</h6>
              <p>Original pay: <span>{formatMoney(payRun.originalSalary)}</span></p>
              <p>Deducted payout amount: <span>{formatMoney(payRun.deductedPayoutAmount)}</span></p>
              <p>Deducted by employer: <span>{formatMoney(payRun.deductedPayoutAmountByEmployer)}</span></p>
              <p>Deducted fee amount: <span>{formatMoney(payRun.deductedFeeAmount)}</span></p>
              <p>Pay after deductions: <span>{formatMoney(payRun.salaryAfterDeductions)}</span></p>
            </div>
          </Card>
        </div>

        <h6>Payout attempts ({payRun.payoutAttempts.length})</h6>
        <div style={{paddingLeft: 10}}>
          <PayoutAttemptList payRun={payRun}/>
        </div>
      </div>

      <CompletePayRunModal
        payRun={payRun}
        onConfirmClick={() => onConfirmManualHandlingClick(payRun.id)}
        onCloseClick={() => setConfirmModalVisible(false)}
        visible={confirmModalVisible}
      />
    </Modal>
  )
}

export default PayRunModal

const useStyles = createUseStyles({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10
  },
  modalSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
  },
  card: {
    display: 'flex',
    flexBasis: '30%',
    flexGrow: 1,
    minWidth: 240,
    padding: 10
  },
  cardContent: {
    '& h6': {
      marginBottom: 10,
    },
    '& p': {
      fontSize: 12,
      margin: 0,
      '& span': {
        fontFamily: 'Aestetico-Bold',
        color: Colors.darkGray2,
      }
    }
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    padding: 6,
    gap: 6,
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    },
  }
})
