import { LogLevel, PopupRequest, RedirectRequest } from "@azure/msal-browser";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || ''
const TENANT_ID = process.env.REACT_APP_TENANT_ID || ''
const SCOPE = process.env.REACT_APP_SCOPE || ''

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'https://cappy-api-test-app.azurewebsites.net/backoffice/' // 'https://localhost:5001/backoffice/'
const PROTECT_API_ENDPOINT = process.env.REACT_APP_PROTECT_API_ENDPOINT || 'https://cappy-protect-test-app.azurewebsites.net/' // 'https://localhost:7281/'
const COMPANY_MONITOR_API_ENDPOINT = process.env.REACT_APP_COMPANY_MONITOR_API_ENDPOINT || 'https://localhost:7185/backoffice/'
const COMMUNICATION_API_ENDPOINT = process.env.REACT_APP_COMMUNICATION_API_ENDPOINT || 'https://cappy-communication-test-app.azurewebsites.net/'

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: CLIENT_ID,
        authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    },
    cache: {
        storeAuthStateInCookie: isIE || isEdge || isFirefox
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    console.log('logger callback contains PII')
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            }
        }
    }
}

export const loginRequest: RedirectRequest | PopupRequest = {
    scopes: [SCOPE],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};

export const restConfig = {
    defaultBaseUrl: API_ENDPOINT,
    protectBaseUrl: PROTECT_API_ENDPOINT,
    companyMonitorBaseURl: COMPANY_MONITOR_API_ENDPOINT,
    communicationBaseUrl: COMMUNICATION_API_ENDPOINT,
    routes: {
        customers: API_ENDPOINT + 'customers',
        employers: API_ENDPOINT + 'employers',
        invoicePayoutProviders: API_ENDPOINT + 'invoicepayoutproviders',
        employees: API_ENDPOINT + 'employees',
        users: API_ENDPOINT + 'users',
        transactions: API_ENDPOINT + 'transactions',
        reconciliations: API_ENDPOINT + 'reconciliations',
        bankAccounts: API_ENDPOINT + 'bankaccounts',
        creditors: API_ENDPOINT + 'creditors',
        shifts: API_ENDPOINT + 'shifts',
        payDeviations: API_ENDPOINT + 'payDeviations',
        aml: COMPANY_MONITOR_API_ENDPOINT + 'aml',
        companyMonitor: COMPANY_MONITOR_API_ENDPOINT + 'companymonitor',
        companyCheck: COMPANY_MONITOR_API_ENDPOINT + 'companycheck',
        nordea: API_ENDPOINT + 'nordea',
        fortnox: API_ENDPOINT + 'fortnox',
        taxTables: API_ENDPOINT + 'taxtables',
        paydays: API_ENDPOINT + 'paydays',
        markets: API_ENDPOINT + 'markets',
        campaigns: API_ENDPOINT + 'campaigns',
        dashboardMetrics: API_ENDPOINT + 'dashboard',
        protectIssues: PROTECT_API_ENDPOINT + 'issues',
        protectRules: PROTECT_API_ENDPOINT + 'rules',
        protectCustomers: PROTECT_API_ENDPOINT + 'customers',
        whistleblower: API_ENDPOINT + 'whistleblower',
        inAppMessages: API_ENDPOINT + 'inappmessages',
        learning: API_ENDPOINT + 'learning',
        payRun: API_ENDPOINT + 'payrun',
        employerPayRun: API_ENDPOINT + 'payrun/employerPayRuns',
        communicationOptOut: COMMUNICATION_API_ENDPOINT + 'communicationOptOut'
    }
}
