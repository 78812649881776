import {useEffect, useRef} from "react";
import usePayRunDashboardActions from "../../hooks/usePayRunDashboardActions";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import {autoUpdateIntervalChanged} from "../../redux/slices/payRunDashboardSlice";

const usePayRunDashboardAutoUpdate = () => {
  const dispatch = useAppDispatch()

  const {
    getIncompleteEmployerPayRuns,
    getIncompletePayRuns,
    getFrozenPayRuns
  } = usePayRunDashboardActions()

  const autoUpdateInterval = useAppSelector(state => state.payRunDashboard.autoUpdateInterval)
  const incompletePayRunsPage = useAppSelector(state => state.payRunDashboard.incompletePayRuns)
  const frozenPayRunsPage = useAppSelector(state => state.payRunDashboard.frozenPayRuns)

  const intervalIdRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current)
      }
    };
  }, [])

  useEffect(() => {
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current)
      intervalIdRef.current = null
    }

    if (autoUpdateInterval) {
      intervalIdRef.current = setInterval(() => {
        performUpdate()
      }, autoUpdateInterval * 1000)
    }
  }, [autoUpdateInterval, incompletePayRunsPage, frozenPayRunsPage])

  const performUpdate = () => {
    getIncompletePayRuns(
      incompletePayRunsPage.searchTerm,
      incompletePayRunsPage.pageIndex,
      incompletePayRunsPage.pageSize
    )

    getFrozenPayRuns(
      frozenPayRunsPage.searchTerm,
      frozenPayRunsPage.pageIndex,
      frozenPayRunsPage.pageSize
    )

    getIncompleteEmployerPayRuns()
  }


  const setAutoUpdateInterval = (interval: number | null) => {
    dispatch(autoUpdateIntervalChanged(interval))
  }

  return {
    autoUpdateInterval,
    setAutoUpdateInterval
  }
}

export default usePayRunDashboardAutoUpdate
