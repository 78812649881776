import Modal from '../Modal'
import {EmployerPayRunDashboardModel} from "../../redux/types";
import {formatDateString} from "../../utils/dateHelper";
import Link from '../Link';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBuilding, faCalendarDay, faLink, faUnlink, faUserTie} from "@fortawesome/free-solid-svg-icons";
import {Colors} from "../../constants/colors";
import {createUseStyles} from "react-jss";
import Badge from "react-bootstrap/Badge";
import Card from "../Card";
import { Toolbar, ToolbarItem } from '../Learning/Toolbar';
import usePayRunDashboardActions from '../../hooks/usePayRunDashboardActions';


interface Props {
  employerPayRun: EmployerPayRunDashboardModel | undefined
  onCloseClick?: () => void
  visible: boolean
}

const EmployerPayRunModal = ({employerPayRun, onCloseClick, visible}: Props) => {
  const styles = useStyles()

  const {ignoreWhenBankAccountTransactionMatching, includeWhenBankAccountTransactionMatching} = usePayRunDashboardActions()

  const handleOnClose = () => {
    onCloseClick && onCloseClick()
  }

  if (!employerPayRun) {
    return null
  }

  const title = `Employer pay run for ${employerPayRun.customerName}`

  return (
    <Modal
      show={visible}
      title={title}
      okButtonTitle='Close'
      hideCancelButton={true}
      onHide={() => handleOnClose()}
      onOkClick={() => handleOnClose()}
      onCancelClick={() => handleOnClose()}
      size={'lg'}>

      <div className={styles.modal}>
      <Toolbar>
        {employerPayRun && employerPayRun.ignoreWhenMatchingBankAccountTransactions &&
          <ToolbarItem icon={faLink} title={"Include when transaction matching"} display={"iconAndText"}
                      onClick={() => {
                        includeWhenBankAccountTransactionMatching(employerPayRun.id)
                        handleOnClose()
                      }}/>
        }
        {employerPayRun && !employerPayRun.ignoreWhenMatchingBankAccountTransactions &&
          <ToolbarItem icon={faUnlink} title={"Ignore when transaction matching"} display={"iconAndText"}
                      onClick={() => {
                        ignoreWhenBankAccountTransactionMatching(employerPayRun.id)
                        handleOnClose()
                      }}/>
        }
        </Toolbar>

        <div className={styles.modalSection}>
          <Badge variant={"secondary"}>{`Id: ${employerPayRun.id}`}</Badge>
          {employerPayRun.offCycle && <Badge variant={"info"}>Off cycle</Badge>}
        </div>

        <div className={styles.modalSection}>
          <Link to={`/customers/${employerPayRun.customerId}`}>
            <div className={styles.linkContainer}>
              <FontAwesomeIcon icon={faBuilding} color={Colors.white}/>
              Customer: {employerPayRun.customerName}
            </div>
          </Link>

          <Link to={`/employers/${employerPayRun.employerId}`}>
            <div className={styles.linkContainer}>
              <FontAwesomeIcon icon={faUserTie} color={Colors.white}/>
              Employer: {employerPayRun.customerName}
            </div>
          </Link>

          <Link to={`/paydays/${employerPayRun.paydayId}`}>
            <div className={styles.linkContainer}>
              <FontAwesomeIcon icon={faCalendarDay} color={Colors.white}/>
              Payday: {formatDateString(employerPayRun.paymentDate)}
            </div>
          </Link>
        </div>

        <div className={styles.modalSection}>
          <Card className={`${styles.card} card`}>
            <div className={styles.cardContent}>
              <h6>Details</h6>
              <p>Payment date: <span>{formatDateString(employerPayRun.paymentDate)}</span></p>
              <p>Payment date string: <span>{employerPayRun.paymentDateString}</span></p>
              <p>Employer deduction payment bank account transaction
                id: <span>{employerPayRun.employerDeductionPaymentBankAccountTransactionId ?? '-'}</span></p>
              <p>Employer deduction payment bank account batch transaction item
                id: <span>{employerPayRun.employerDeductionPaymentBankAccountBatchTransactionItemId ?? '-'}</span></p>
            </div>
          </Card>
        </div>
      </div>
    </Modal>
  )
}

export default EmployerPayRunModal

const useStyles = createUseStyles({
  modal: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10
  },
  modalSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
  },
  card: {
    display: 'flex',
    flexBasis: '30%',
    flexGrow: 1,
    minWidth: 240,
    padding: 10
  },
  cardContent: {
    '& h6': {
      marginBottom: 10,
    },
    '& p': {
      fontSize: 12,
      margin: 0,
      '& span': {
        fontFamily: 'Aestetico-Bold',
        color: Colors.darkGray2,
      }
    }
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 8,
    padding: 6,
    gap: 6,
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    },
  }
})
