import {useState} from "react";
import {EmployeeModel} from "../../redux/types";
import useEmployeeActions from "../../hooks/useEmployeeActions";
import {useAppSelector} from "../../redux/hooks";

interface EmployeeModelColumnDefinition {
  title: string
  extractor: (employee: EmployeeModel) => string
  style?: 'Cell' | 'Badge'
}

const useEmployeeCsvExport = () => {
  const {fetchEmployees} = useEmployeeActions()
  const optOuts = useAppSelector(state => state.optOut.optOuts)
  const [exporting, setExporting] = useState(false)

  const columns: EmployeeModelColumnDefinition[] = [
    {
      title: 'Given Name',
      extractor: (item: EmployeeModel) => item.givenName || ''
    },
    {
      title: 'Family Name',
      extractor: (item: EmployeeModel) => item.familyName || ''
    },
    {
      title: 'External ID',
      extractor: (item: EmployeeModel) => item.externalId || ''
    },
    {
      title: 'Identification Number',
      extractor: (item: EmployeeModel) => item.identificationNumber || ''
    },
    {
      title: 'Email',
      extractor: (item: EmployeeModel) => item.email || ''
    },
    {
      title: 'Phone Number',
      extractor: (item: EmployeeModel) => item.phoneNumber || ''
    },
    {
      title: 'Type',
      extractor: (item: EmployeeModel) => item.type
    },
    {
      title: 'Connected User',
      extractor: (item: EmployeeModel) => item.connectedUser ? `${item.connectedUser.givenName} ${item.connectedUser.familyName}` : ''
    },
    {
      title: 'Employer Name',
      extractor: (item: EmployeeModel) => item.employerName
    },
    {
      title: 'Pay Calculation Type',
      extractor: (item: EmployeeModel) => item.payCalculationType
    },
    {
      title: 'Bank Account Number',
      extractor: (item: EmployeeModel) => item.bankAccountNumber || ''
    },
    {
      title: 'Department',
      extractor: (item: EmployeeModel) => item.department || ''
    },
    {
      title: 'Category',
      extractor: (item: EmployeeModel) => item.category || ''
    },
    {
      title: 'Location',
      extractor: (item: EmployeeModel) => item.location || ''
    },
    {
      title: 'Cost Center',
      extractor: (item: EmployeeModel) => item.costCenter || ''
    },
    {
      title: 'Payroll File Identifier',
      extractor: (item: EmployeeModel) => item.payrollFileIdentifier || ''
    },
    {
      title: 'Salary Earning On Weekends',
      extractor: (item: EmployeeModel) => item.salaryEarningOnWeekends ? 'Yes' : 'No'
    },
    {
      title: 'Activated',
      extractor: (item: EmployeeModel) => item.activated ? 'Yes' : 'No'
    },
    {
      title: 'Start Date',
      extractor: (item: EmployeeModel) => item.startDate || ''
    },
    {
      title: 'Initial Start Date',
      extractor: (item: EmployeeModel) => item.initialStartDate || ''
    },
    {
      title: 'Activation Date',
      extractor: (item: EmployeeModel) => item.activationDate || ''
    },
    {
      title: 'Termination Date',
      extractor: (item: EmployeeModel) => item.terminationDate || ''
    },
    {
      title: 'Extend Termination To Upcoming Payday',
      extractor: (item: EmployeeModel) => item.extendTerminationToUpcomingPayday ? 'Yes' : 'No'
    },
    {
      title: 'Paused',
      extractor: (item: EmployeeModel) => item.paused ? 'Yes' : 'No'
    },
    {
      title: 'Pause Reason',
      extractor: (item: EmployeeModel) => item.pauseReason || ''
    },
    {
      title: 'Pause Date',
      extractor: (item: EmployeeModel) => item.pauseDate || ''
    },
    {
      title: 'Non Eligible',
      extractor: (item: EmployeeModel) => item.nonEligible ? 'Yes' : 'No'
    },
    {
      title: 'Under Foreclosure',
      extractor: (item: EmployeeModel) => item.underForeclosure ? 'Yes' : 'No'
    },
    {
      title: 'Pending Bank Account Activation',
      extractor: (item: EmployeeModel) => item.pendingBankAccountActivation ? 'Yes' : 'No'
    },
    {
      title: 'Bank Account Activation Failed',
      extractor: (item: EmployeeModel) => item.bankAccountActivationFailed ? 'Yes' : 'No'
    },
    {
      title: 'Tax Settings',
      extractor: (item: EmployeeModel) => JSON.stringify(item.taxSettings)
    },
    {
      title: 'Status',
      extractor: (item: EmployeeModel) => item.status
    },
    {
      title: 'Payout Settings',
      extractor: (item: EmployeeModel) => JSON.stringify(item.payoutSettings)
    },
    {
      title: 'Gross Salary',
      extractor: (item: EmployeeModel) => item.grossSalary ? `${item.grossSalary.amount} ${item.grossSalary.currencyCode}` : ''
    },
    {
      title: 'Gross Hourly Wage',
      extractor: (item: EmployeeModel) => item.grossHourlyWage ? `${item.grossHourlyWage.amount} ${item.grossHourlyWage.currencyCode}` : ''
    },
    {
      title: 'Handle Vacation Pay',
      extractor: (item: EmployeeModel) => item.handleVacationPay ? 'Yes' : 'No'
    },
    {
      title: 'Vacation Pay Percent',
      extractor: (item: EmployeeModel) => item.vacationPayPercent ? item.vacationPayPercent.toString() : ''
    },
    {
      title: 'Last Pay Data Update',
      extractor: (item: EmployeeModel) => item.lastPayDataUpdate || ''
    },
    {
      title: 'Is Eligible For Activation Reminder',
      extractor: (item: EmployeeModel) => item.isEligibleForActivationReminder ? 'Yes' : 'No'
    },
    {
      title: 'Welcome Message Sent',
      extractor: (item: EmployeeModel) => item.welcomeMessageSent ? 'Yes' : 'No'
    },
    {
      title: 'Last Activation Reminder Date',
      extractor: (item: EmployeeModel) => item.lastActivationReminderDate || ''
    },
    {
      title: 'Next Activation Reminder Date',
      extractor: (item: EmployeeModel) => item.nextActivationReminderDate || ''
    },
    {
      title: 'Opt Out',
      extractor: (item: EmployeeModel) => {
        if (!optOuts) return '?'

        const employeeOptOut = optOuts.employeeIds.find(optOut => optOut === item.id)
        const userOptOut = optOuts.userIds.find(optOut => optOut === item.connectedUser?.id)

        return employeeOptOut || userOptOut ? 'Yes' : 'No'
      }
    }
  ];

  const exportEmployees = async (employerId: string | null, searchTerm: string | null) => {

    const convertToCSV = (employees: EmployeeModel[]): string => {
      const headers = columns.map(col => col.title).join(';');
      const rows = employees.map(employee => columns.map(col => col.extractor(employee)).join(';'))
      return `${headers}\n${rows.join('\n')}`;
    };

    try {
      setExporting(true);
      const employees = await fetchEmployees(employerId, searchTerm ?? '');

      if (employees.length === 0) {
        console.warn('No employees found to export');
        return;
      }

      const csvContent = convertToCSV(employees);
      const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = "employees.csv";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error exporting employees:', error);
    } finally {
      setExporting(false);
    }
  }

  return {exporting, exportEmployees}
}

export default useEmployeeCsvExport
