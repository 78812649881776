import EmployeeList from "../components/EmployeeList/EmployeeList";
import {EmployeeListItemModel} from "../redux/types";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import useEmployeeActions from "../hooks/useEmployeeActions";
import {useEffect, useState} from "react";
import {filterChanged, pageIndexChanged} from "../redux/slices/employeeSlice";
import useDidUpdateEffect from "../hooks/useDidUpdateEffect";
import Button from "../components/Button";
import {createUseStyles} from "react-jss";
import FullScreenLoadingIndicator from "../components/FullScreenLoadingIndicator";
import EmployerSelector from "../components/EmployerSelector";
import {SearchResultItem} from "../components/SearchAndSelectField";
import InputField from "../components/InputField";
import Card from "../components/Card";
import useEmployeeCsvExport from "../components/EmployeeList/useEmployeeCsvExport";
import {AllListItemColumnDefinitions} from "../components/EmployeeList/ListItemColumnDefinition";

const Employees = () => {
  const styles = useStyles()
  const optOuts = useAppSelector(state => state.optOut.optOuts)

  const EmployeeListItemModelOptOutColumn = {
    title: 'Opt Out',
    extractor: (item: EmployeeListItemModel) => {
      if (!optOuts) return '?'

      const employeeOptOut = optOuts.employeeIds.find(optOut => optOut === item.id)
      const userOptOut = optOuts.userIds.find(optOut => optOut === item.userId)

      return employeeOptOut || userOptOut ? 'Yes' : 'No'
    }
  }

  const {exporting, exportEmployees} = useEmployeeCsvExport()

  const dispatch = useAppDispatch()
  const {getEmployees} = useEmployeeActions()

  const employeesOnPage = useAppSelector(state => state.employee.employeesOnPage)
  const totalCount = useAppSelector(state => state.employee.totalCount)
  const searchTerm = useAppSelector(state => state.employee.searchTerm)
  const searchEmployerId = useAppSelector(state => state.employee.employerId)
  const pageIndex = useAppSelector(state => state.employee.pageIndex)
  const pageSize = useAppSelector(state => state.employee.pageSize)

  const [employerId, setEmployerId] = useState<string | null>(null)
  const [employerName, setEmployerName] = useState<string>('')

  useEffect(() => {
    if (employerId !== searchEmployerId)
      dispatch(filterChanged({searchTerm: searchTerm ?? '', employerId: employerId}))
  }, [employerId, searchEmployerId, searchTerm, dispatch])

  useDidUpdateEffect(() => {
    if (searchEmployerId === undefined) return

    getEmployees(searchEmployerId, searchTerm ?? '', pageIndex, pageSize)
  }, [searchEmployerId, pageIndex, searchTerm])

  const onPageChange = (pageIndex: number) => {
    dispatch(pageIndexChanged(pageIndex))
  }

  const onFilterChange = (value: string) => {
    dispatch(filterChanged({searchTerm: value.toLowerCase(), employerId: employerId}))
  }

  const onEmployerSelected = (item: SearchResultItem | null) => {
    setEmployerId(item?.value || null)
    setEmployerName(item?.name || '')
  }

  return (
    <div className={styles.container}>
      <h1>Employees</h1>

      <div className={styles.actionBar}>
        <Button title={"Export"} onClick={() => exportEmployees(employerId, searchTerm)}/>
      </div>

      <Card className={styles.card}>
        <InputField
          className={styles.input}
          id="employeeSearchField"
          placeholder="Search employees"
          value={searchTerm || ''}
          onEnterKeyOrBlur={onFilterChange}
        />
        <EmployerSelector onEmployerSelected={onEmployerSelected} value={employerName}/>
      </Card>

      <EmployeeList
        employeesOnPage={employeesOnPage}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalCount={totalCount}
        onPageChange={onPageChange}
        columns={AllListItemColumnDefinitions.concat([EmployeeListItemModelOptOutColumn])}
      />
      <FullScreenLoadingIndicator visible={exporting}/>
    </div>
  )
}

export default Employees

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10,
    gap: 10
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 10,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  input: {
    marginTop: 5
  }
})
