import React, {useEffect, useState} from 'react'
import Switch from '../components/Switch'
import {createUseStyles} from 'react-jss'
import {Link, useHistory, useParams} from 'react-router-dom';
import Card from '../components/Card'
import CardSection from '../components/CardSection'
import DetailsCard from '../components/DetailsCard'
import {Colors} from '../constants/colors'
import {formatDateString, formatDateTimeString} from '../utils/dateHelper'
import {roundToMaxTwoDecimals} from '../utils/numberHelper'
import {CreateFreePayoutModel, PaydayDataModel, PayoutSettings, UserPayoutCooldownPeriodModel} from '../redux/types'
import EditPayoutSettingsModal from '../components/EditPayoutSettingsModal';
import ConfirmModal from '../components/ConfirmModal'
import useUserActions from '../hooks/useUserActions';
import {useAppSelector} from '../redux/hooks';
import FullScreenLoadingIndicator from '../components/FullScreenLoadingIndicator';
import CreateFreePayoutModal from '../components/CreateFreePayoutModal';
import NavLink from '../components/NavLink';
import {faArrowLeft, faPauseCircle} from '@fortawesome/free-solid-svg-icons';
import DefaultLink from '../components/Link';
import {titleMarginBottom} from '../constants/layout';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Badge from "react-bootstrap/Badge";
import SetUserPhoneModal from '../components/SetUserPhoneModal';
import Button from '../components/Button';
import useCommunicationOptOutActions from '../hooks/useCommunicationOptOutActions';
import TextAreaModal from '../components/TextAreaModal';

const UserDetails = () => {
  const styles = useStyles()
  const {
    getUserDetails,
    setPayoutSettings,
    setLockState,
    setPhoneNumberVerificationState,
    setPhoneNumber,
    setInternalComment,
    createFreePayout
  } = useUserActions()
  
  const { setCommunicationOptOutStateForUser } = useCommunicationOptOutActions()

  const [payoutSettingsEditModalVisible, setPayoutSettingsEditModalVisible] = useState(false)
  const [confirmLockUserModalVisible, setConfirmLockUserModalVisible] = useState(false)
  const [createFreePayoutModalVisible, setCreateFreePayoutModalVisible] = useState(false)
  const [userPhoneModalVisible, setUserPhoneModalVisible] = useState(false)
  const [internalCommentModalVisible, setInternalCommentModalVisible] = useState(false)
  const history = useHistory();

  const loading = useAppSelector(state => state.user.loading)
  const userDetails = useAppSelector(state => state.user.userDetails)
  const optOuts = useAppSelector(state => state.optOut.optOuts)
  
  const {id} = useParams<{ id: string }>();

  useEffect(() => {
    if (userDetails == null || userDetails.id !== id) {
      getUserDetails(id)
    }
  }, [])

  const isLocked = () => {
    return userDetails?.state === "locked"
  }

  const handlePayoutSettingsChange = (payoutSettings: PayoutSettings) => {
    setPayoutSettings(id, payoutSettings)
    setPayoutSettingsEditModalVisible(false)
  }

  const handleCreateFreePayout = (freePayout: CreateFreePayoutModel) => {
    createFreePayout(id, freePayout);
    setCreateFreePayoutModalVisible(false)
  }

  const handleLockStateChange = () => {
    if (!isLocked()) {
      setConfirmLockUserModalVisible(true)
    } else {
      setLockState(userDetails?.id as string, false)
    }
  }

  const handleLockUserConfirmed = () => {
    setLockState(userDetails?.id as string, true)
    setConfirmLockUserModalVisible(false)
  }

  const handlePhoneNumberVerificationStateChange = () => {
    if (userDetails)
      setPhoneNumberVerificationState(userDetails.id as string, !userDetails.mobilePhoneVerificationDisabled)
  }

  const handleUserPhoneUpdated = (phoneNumber: string) => {
    if (userDetails)
      setPhoneNumber(userDetails.id, phoneNumber)

    setUserPhoneModalVisible(false)
  }

  const handleInternalCommentChanged = (comment: string) => {
    if (userDetails)
      setInternalComment(userDetails.id, comment)

    setInternalCommentModalVisible(false)
  }

  const handleCommunicationOptOutStateChange = () => {
    if (userDetails) {
      var currentState = (optOuts?.userIds.find(x => x == userDetails?.id) != undefined)
      setCommunicationOptOutStateForUser(userDetails.id as string, !currentState)
    } 
  }

  const renderManuallyLockedSwitch = () => {
    return (
      <Switch
        id='lockSwitch'
        key='lockSwitch'
        checked={isLocked()}
        label={isLocked() ? 'Manual lock on' : 'Manual lock off'}
        onChange={() => handleLockStateChange()}/>
    )
  }

  const renderPhoneNumberVerificationSwitch = () => {
    return (
      <Switch
        id='phoneNumberVerification'
        key='phoneNumberVerification'
        checked={!userDetails?.mobilePhoneVerificationDisabled}
        label={!userDetails?.mobilePhoneVerificationDisabled ? 'Phone number verification on' : 'Phone number verification off'}
        onChange={() => handlePhoneNumberVerificationStateChange()}/>
    )
  }

  const renderPayoutSettingsCardBody = (payoutSettings: PayoutSettings | undefined, noValueString: string) => {
    return (
      <div>
        <p>Min amount per payout: <span>{payoutSettings?.minAmountPerPayout != null ? payoutSettings?.minAmountPerPayout : noValueString }</span></p>
        <p>Max amount per pay period: <span>{payoutSettings?.maxAmountPerPayday != null ? payoutSettings?.maxAmountPerPayday : noValueString}</span></p>
        <p>Max percentage per pay period: <span>{payoutSettings?.maxPercentPerPayday != null ? payoutSettings?.maxPercentPerPayday : noValueString}</span></p>
        {/* <p>Max amount per payout: <span>{employer?.payoutSettings?.maxAmountPerPayout || notSpecified}</span></p> */}
        <p>Max number of payouts per pay period: <span>{payoutSettings?.maxNumberOfPayoutsPerPayday != null ? payoutSettings?.maxNumberOfPayoutsPerPayday : noValueString}</span></p>
        <p>Fee: <span>{payoutSettings?.fee != null ? payoutSettings?.fee : noValueString}</span></p>
        <p>Currency: <span>{payoutSettings?.currency?.toUpperCase() || noValueString}</span></p>
        <p>Enable custom shift settings: <span>{payoutSettings?.enableCustomShiftSettings?.toString() || noValueString}</span></p>
        <p>Max percentage per pay period for custom shifts: <span>{payoutSettings?.maxCustomPercentPerPayday != null ? payoutSettings?.maxCustomPercentPerPayday : noValueString}</span></p>
      </div>
    )
  }

  const renderPaydaysHeader = (employerId: string) => {
    const payoutSettings = userDetails?.employerPaydayData?.find(x => x.employerId === employerId)?.payoutSettings

    return (
      <Card className={styles.paydayContainer}>
        <div className={styles.payDayListCell}>
          <p>Payment date</p>
        </div>
        <div className={styles.payDayListCell}>
          <p>Total income gross/net ({payoutSettings?.currency?.toUpperCase()})</p>
        </div>
        <div className={styles.payDayListCell}>
          <p>Gross deviations ({payoutSettings?.currency?.toUpperCase()})</p>
        </div>
        <div className={styles.payDayListCell}>
          <p>Hours completed/planned</p>
        </div>
        <div className={styles.payDayListCell}>
          <p>Total payout/fee amount ({payoutSettings?.currency?.toUpperCase()})</p>
        </div>
        <div className={styles.payDayListCell}>
          <p>Num. payouts (done/left)</p>
        </div>
        <div className={styles.payDayListCell}>
          <p>Available ({payoutSettings?.currency?.toUpperCase()})</p>
        </div>
      </Card>
    )
  }

  const renderPaydays = (employerId: string) => {
    const paydays = userDetails?.employerPaydayData?.find(x => x.employerId === employerId)?.paydays

    if (paydays?.length ?? 0 > 0) {
      const orderedPaydays = [...(paydays as PaydayDataModel[])].sort((a, b) => (new Date(a.payday.paymentDate) > new Date(b.payday.paymentDate)) ? -1 : 1)

      return orderedPaydays?.map((paydayData, index) => {
        return (
          <Link to={{pathname: `/paydaydata/${employerId}/${paydayData.payday.id}`}} key={`payday_${index}`}>
            <Card className={`${styles.paydayContainer} ${styles.hover} ${!paydayData.payday.locked && styles.upcoming}`}>
              <div className={styles.payDayListCell}>
                <p><span>{formatDateString(paydayData.payday.paymentDate)}</span></p>
              </div>
              <div className={styles.payDayListCell}>
                <p><span>{roundToMaxTwoDecimals(paydayData.income.grossTotalAmount.amount).toLocaleString('sv-SE')} / {roundToMaxTwoDecimals(paydayData.income.netTotalAmount.amount).toLocaleString('sv-SE')}</span></p>
              </div>
              <div className={styles.payDayListCell}>
                <p><span>{roundToMaxTwoDecimals(paydayData.deviations.aggregatedGrossAmount.amount).toLocaleString('sv-SE')}</span></p>
              </div>
              <div className={styles.payDayListCell}>
                <p><span>{roundToMaxTwoDecimals(paydayData.completedShifts.aggregatedHours)} / {roundToMaxTwoDecimals(paydayData.plannedShifts?.aggregatedHours ?? 0)}</span></p>
              </div>
              <div className={styles.payDayListCell}>
                <p><span>{roundToMaxTwoDecimals(paydayData.payoutHistory.aggregatedAmount.amount).toLocaleString('sv-SE')} / {roundToMaxTwoDecimals(paydayData.payoutHistory.aggregatedFeeAmount.amount).toLocaleString('sv-SE')}</span></p>
              </div>
              <div className={styles.payDayListCell}>
                <p><span>{paydayData.payoutHistory.payoutCount} / {paydayData.payoutAvailability.availablePayoutCount ?? '∞'}</span></p>
              </div>
              <div className={styles.payDayListCell}>
                <p><span>{roundToMaxTwoDecimals(paydayData.payoutAvailability.availableAmount.amount).toLocaleString('sv-SE')}</span></p>
              </div>
            </Card>
          </Link>
        )
      })
    }
  }

  const renderEmployments = () => {
    return userDetails?.connectedEmployees?.map((employment, index) => {
      const payoutSettings = userDetails.employerPaydayData?.find(x => x.employerId === employment.employerId)?.payoutSettings

      return (
        <div key={`employment_${index}`} className={styles.employmentContainer}>
          <h5>{employment.employerName}</h5>
          <CardSection>
            <DetailsCard>
              <h6>General information</h6>
              <p>Employment number: <span>{employment.externalId}</span></p>
              <p>State: <span>{employment.status}</span></p>
              <p>Connected at: <span>{formatDateTimeString(employment.connectedToUserDate)}</span></p>
              <p>Activation date: <span>{formatDateTimeString(employment.activationDate)}</span></p>
              <p>Employee: <DefaultLink to={`/employees/${employment.id}`}>Link</DefaultLink></p>
            </DetailsCard>
            <DetailsCard>
              <h6>Effective payout settings</h6>
              {renderPayoutSettingsCardBody(payoutSettings, 'not set')}
            </DetailsCard>
          </CardSection>
          <CardSection>
            <DetailsCard>
              <h6>Paydays</h6>
              {renderPaydaysHeader(employment.employerId)}
              {renderPaydays(employment.employerId)}
            </DetailsCard>
          </CardSection>
        </div>
      )
    })
  }

  const renderPayoutMethods = () => {
    return userDetails?.payoutMethods?.map((payoutMethod, index) => {
      return (
        <Card className={styles.card} key={`payoutMethod_${index}`}>
          <p>Type: <span>{payoutMethod.payoutMethodType}</span></p>
          <p>Target: <span>{payoutMethod.target}</span></p>
        </Card>
      )
    })
  }

  const renderPayoutCooldownPeriods = () => {
    if (!userDetails?.payoutCooldownPeriods || userDetails?.payoutCooldownPeriods.length === 0) {
      return null
    }

    const sortedCooldownPeriods = [...(userDetails.payoutCooldownPeriods as UserPayoutCooldownPeriodModel[])]
      .sort((a, b) => (~~b.isActive - ~~a.isActive) || Date.parse(b.end) - Date.parse(a.end))
      .slice(0, 10)

    return (
      <CardSection>
        <DetailsCard>
          <h6>{`Payout cooldown periods (showing ${sortedCooldownPeriods.length} of ${userDetails.payoutCooldownPeriods.length})`}</h6>
          <div className={styles.cooldownPeriodCardContainer}>
            {
              sortedCooldownPeriods.map((cooldownPeriod, index) => {
                const signingState = cooldownPeriod.state === 'active' ? 'Signed' : cooldownPeriod.state
                return (
                  <Card className={`${styles.card} ${styles.cooldownPeriodCard}`} key={`payoutCooldownPeriod_${index}`}>
                    <FontAwesomeIcon icon={faPauseCircle}
                                     color={cooldownPeriod.isActive ? Colors.yen_600 : Colors.darkGray3} size='lg'/>
                    <div style={{display: 'flex', flex: 1, flexDirection: 'column'}}>
                      <p>Start: <span>{formatDateTimeString(cooldownPeriod.start)}</span></p>
                      <p>End: <span>{formatDateTimeString(cooldownPeriod.end)}</span></p>
                      <p>Signing state: <span>{signingState}</span></p>
                      <div style={{display: 'flex', flexDirection: "row-reverse"}}>
                        {cooldownPeriod.isActive ?
                          <Badge variant={"warning"}>Active</Badge> :
                          <Badge variant={"secondary"}>Inactive</Badge>}
                      </div>
                    </div>
                  </Card>
                )
              })
            }
          </div>
        </DetailsCard>
      </CardSection>
    )
  }

  const renderFreePayouts = () => {
    return (
      <CardSection>
        <DetailsCard iconType='Add' onClick={() => setCreateFreePayoutModalVisible(true)}>
          <h6>Free payouts</h6>
          <p>Available free payouts: <span>{userDetails?.freePayouts?.available}</span></p>
          <p>Used free payouts: <span>{userDetails?.freePayouts?.used}</span></p>
        </DetailsCard>
      </CardSection>
    )
  }

  const renderCommunicationOptOutSwitch = () => {
    return (
      <Switch
        id='communicationOptOutSwitch'
        key='communicationOptOutSwitch'
        checked={(optOuts?.userIds.find(x => x == userDetails?.id) != undefined)}
        label='Communication opt-out'
        onChange={() => handleCommunicationOptOutStateChange()} />
    )
  }

  const notSpecified = 'not specified'

  return (
    <div className={styles.container}>
      <h1>{userDetails?.givenName} {userDetails?.familyName}</h1>
      <div className={styles.actionBar}>
        <NavLink title='Back' icon={faArrowLeft} onClick={() => history.goBack()}/>
        <div className={styles.actionBarRight}>
          <div style={{paddingBottom: 8}}>
            <Button 
              title='Set phone number'
              onClick={() => setUserPhoneModalVisible(true)} 
            />
          </div>
          <div style={{paddingBottom: 8}}>
            {renderPhoneNumberVerificationSwitch()}
          </div>
          <div style={{paddingBottom: 8}}>
            {renderManuallyLockedSwitch()}
          </div>
          <div style={{paddingBottom: 8}}>
            {renderCommunicationOptOutSwitch()}
          </div>
        </div>
      </div>
      <CardSection>
        <DetailsCard>
          <h6>General information</h6>
          <p>Name: <span>{userDetails?.givenName} {userDetails?.familyName}</span></p>
          <p>Identification number: <span>{userDetails?.identificationNumber || notSpecified}</span></p>
          <p>State: <span>{userDetails?.state}</span></p>
          <p>Created date: <span>{formatDateTimeString(userDetails?.createdDate)}</span></p>
          {userDetails?.accountDeletionRequestDate && <p>Account deletion request date: <span>{formatDateString(userDetails?.accountDeletionRequestDate)}</span></p>}
          {userDetails?.accountDeletionDate && <p>Account deletion date: <span>{formatDateString(userDetails?.accountDeletionDate)}</span></p>}
          <p>Data collection consent: <span>{userDetails?.dataCollectionConsent ? 'yes' : 'no'}</span></p>
          <p>Activation guide completed: <span>{userDetails?.activationGuideCompleted ? 'yes' : 'no'}</span></p>
          <p>Email: <span>{userDetails?.email || notSpecified}</span></p>
          <p>Phone number: <span>{userDetails?.mobilePhone || notSpecified}</span></p>
          {userDetails?.unverifiedMobilePhone && <p>Unverified phone number: <span>{userDetails?.unverifiedMobilePhone}</span></p>}
          <p>Phone number verification: <span>{userDetails?.mobilePhoneVerificationDisabled ? 'off' : 'on'}</span></p>
          <p>Market: <span>{userDetails?.marketName || notSpecified}</span></p>
          <p>Language: <span>{userDetails?.preferredLanguage || notSpecified}</span></p>
          <p>Last login: <span>{formatDateTimeString(userDetails?.latestLoginDate)}</span></p>
          <p>Number of logins: <span>{userDetails?.numberOfLogins}</span></p>
          <p>App version: <span>{userDetails?.appVersion}</span></p>
        </DetailsCard>
        <DetailsCard>
          <h6>Payout methods</h6>
          {renderPayoutMethods()}
        </DetailsCard>
        <DetailsCard onClick={() => setPayoutSettingsEditModalVisible(true)}>
          <h6>Payout settings</h6>
          {renderPayoutSettingsCardBody(userDetails?.payoutSettings, 'not set')}
        </DetailsCard>
      </CardSection>
      <CardSection>
        <DetailsCard iconType='Edit' onClick={() => setInternalCommentModalVisible(true)}>
          <h6>Internal comment</h6>
          <p><span>{userDetails?.internalComment}</span></p>
        </DetailsCard>
      </CardSection>
      {renderFreePayouts()}
      {renderPayoutCooldownPeriods()}
      <h2>Employments</h2>
      {renderEmployments()}
      <ConfirmModal
        visible={confirmLockUserModalVisible}
        confirmText={`Are you sure you want to lock this user? The user will not be able to use the app.`}
        onConfirmClick={handleLockUserConfirmed}
        onCancelClick={() => setConfirmLockUserModalVisible(false)}
      />
      <EditPayoutSettingsModal
        visible={payoutSettingsEditModalVisible}
        currencyEditable={false}
        initialValue={userDetails?.payoutSettings || {} as PayoutSettings}
        marketId={userDetails?.marketId ?? ''}
        onCancelClick={() => setPayoutSettingsEditModalVisible(false)}
        onSaveClick={handlePayoutSettingsChange}
      />
      <CreateFreePayoutModal
        visible={createFreePayoutModalVisible}
        onCancelClick={() => setCreateFreePayoutModalVisible(false)}
        onSaveClick={handleCreateFreePayout}
        maxCreateCount={10}
      />
      <SetUserPhoneModal 
        visible={userPhoneModalVisible}
        initialValue={userDetails?.mobilePhone}
        onCancelClick={() => setUserPhoneModalVisible(false)}
        onSaveClick={handleUserPhoneUpdated}
      />
      <TextAreaModal
        title='Internal comment'
        visible={internalCommentModalVisible}
        initialValue={userDetails?.internalComment}
        onCancelClick={() => setInternalCommentModalVisible(false)}
        onSaveClick={handleInternalCommentChanged}
      />
      <FullScreenLoadingIndicator visible={loading}/>
    </div>
  )
}

export default UserDetails

const useStyles = createUseStyles({
  container: {
    '& h1': {
      marginBottom: titleMarginBottom
    },
    '& h2': {
      paddingTop: '30px'
    }
  },
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: -8
  },
  actionBarRight: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 15,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  paydayContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'left',
    padding: '3px 20px',
    margin: '10px 0',
    transition: '0.4s',
    border: '1px solid',
    borderColor: Colors.darkGray5
  },
  hover: {
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  upcoming: {
    borderColor: Colors.euro_600
  },
  payDayListCell: {
    flex: 1,
    padding: '0px 10px'
  },
  employmentContainer: {
    paddingTop: 25,
    paddingBottom: 25
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'left',
    textAlign: 'left',
    padding: '5px 5px',
    margin: '10px 0',
    backgroundColor: Colors.darkGrayCard,
    transition: '0.4s',
    '&:hover': {
      backgroundColor: Colors.darkGrayHover,
    }
  },
  cooldownPeriodCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10
  },
  cooldownPeriodCard: {
    width: 250,
    flexDirection: 'row',
    gap: 10,
    justifyContent: 'flex-start',
    border: '1px solid',
    borderColor: Colors.darkGray5
  },
  activeCooldownPeriod: {
    borderColor: Colors.yen_600
  }
})
