import {restConfig} from "../authConfig";
import {useAppDispatch} from "../redux/hooks";
import {
  employerPayRunIgnoredWhenTransactionMatching,
  employerPayRunIgnoredWhenTransactionMatchingFailure,
  employerPayRunIncludedWhenTransactionMatching,
  employerPayRunIncludedWhenTransactionMatchingFailure,
  frozenPayRunsFetched,
  frozenPayRunsFetchFailure,
  frozenPayRunsFilterChanged,
  frozenPayRunsLoading,
  frozenPayRunsPageIndexChanged,
  incompleteEmployerPayRunsFetched,
  incompleteEmployerPayRunsFetchFailure,
  incompleteEmployerPayRunsLoading,
  incompletePayRunsFetched,
  incompletePayRunsFetchFailure,
  incompletePayRunsFilterChanged,
  incompletePayRunsLoading,
  incompletePayRunsPageIndexChanged
} from "../redux/slices/payRunDashboardSlice";
import {EmployerPayRunDashboardModel, PayRunModel} from "../redux/types";
import useFetchClient from "./useFetchClient"
import {createUrlWithQueryString} from "../utils/url";
import {getPaginationDataFromResponse} from "../utils/responseHelper";

const usePayRunDashboardActions = () => {
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()

  const setIncompletePayRunsPageIndex = (pageIndex: number) => {
    dispatch(incompletePayRunsPageIndexChanged(pageIndex))
  }

  const setIncompletePayRunsFilter = (filter: string) => {
    dispatch(incompletePayRunsFilterChanged(filter))
  }

  const setFrozenPayRunsPageIndex = (pageIndex: number) => {
    dispatch(frozenPayRunsPageIndexChanged(pageIndex))
  }

  const setFrozenPayRunsFilter = (filter: string) => {
    dispatch(frozenPayRunsFilterChanged(filter))
  }

  const getIncompleteEmployerPayRuns = () => {
    dispatch(incompleteEmployerPayRunsLoading())

    const url = restConfig.routes.payRun + '/employerPayRuns'

    return fetchClient.get(url)
      .then(response => {
        dispatch(incompleteEmployerPayRunsFetched(response.data as EmployerPayRunDashboardModel[]))
      })
      .catch(error => {
        console.log('incompleteEmployerPayRunsFetchFailure:', error)
        dispatch(incompleteEmployerPayRunsFetchFailure(error))
      })
  }

  const getIncompletePayRuns = (searchTerm: string, pageIndex: number, pageSize: number) => {
    dispatch(incompletePayRunsLoading())

    const params = {
      query: searchTerm,
      pageNumber: pageIndex,
      pageSize: pageSize,
      frozen: false
    }

    const url = createUrlWithQueryString(restConfig.routes.payRun + '/incompletePayRuns', params)

    return fetchClient.get(url)
      .then(response => {
        const paginationData = getPaginationDataFromResponse(response.raw)
        dispatch(incompletePayRunsFetched({
          items: response.data as PayRunModel[],
          totalCount: paginationData?.totalItemCount ?? 0
        }))
      })
      .catch(error => {
        console.log('incompletePayRunsFetchFailure:', error)
        dispatch(incompletePayRunsFetchFailure(error))
      })
  }

  const getFrozenPayRuns = (searchTerm: string, pageIndex: number, pageSize: number) => {
    dispatch(frozenPayRunsLoading())

    const params = {
      query: searchTerm,
      pageNumber: pageIndex,
      pageSize: pageSize,
      frozen: true
    }
    const url = createUrlWithQueryString(restConfig.routes.payRun + '/incompletePayRuns', params)

    return fetchClient.get(url)
      .then(response => {
        const paginationData = getPaginationDataFromResponse(response.raw)
        dispatch(frozenPayRunsFetched({
          items: response.data as PayRunModel[],
          totalCount: paginationData?.totalItemCount ?? 0
        }))
      })
      .catch(error => {
        console.log('frozenPayRunsFetchFailure: ', error)
        dispatch(frozenPayRunsFetchFailure(error))
      })
  }

  const pausePayRun = (payRunId: string) => {
    const url = restConfig.routes.payRun + '/' + payRunId + '/pause'

    return fetchClient.post(url)
      .then(response => {
        console.log('pausePayRun response:', response)
      })
      .catch(error => {
        console.log('pausePayRun error:', error)
      })
  }

  const resumePayRun = (payRunId: string) => {
    const url = restConfig.routes.payRun + '/' + payRunId + '/resume'

    return fetchClient.post(url)
      .then(response => {
        console.log('resumePayRun response:', response)
      })
      .catch(error => {
        console.log('resumePayRun error:', error)
      })
  }

  const completePayRun = (payRunId: string) => {
    const url = restConfig.routes.payRun + '/' + payRunId + '/complete'

    return fetchClient.post(url)
      .then(response => {
        console.log('completePayRun response:', response)
      })
      .catch(error => {
        console.log('completePayRun error:', error)
      })
  }

  const includeWhenBankAccountTransactionMatching = (employerPayRunId: string) => {
    dispatch(incompleteEmployerPayRunsLoading())   

    const url = restConfig.routes.employerPayRun + `/${employerPayRunId}/include`

    return fetchClient.post(url)
      .then(response => {
          dispatch(employerPayRunIncludedWhenTransactionMatching(employerPayRunId))
      })
      .catch(error => {
          console.log('includeWhenBankAccountTransactionMatching error:', error)
          dispatch(employerPayRunIncludedWhenTransactionMatchingFailure(error))
      })
  }

  const ignoreWhenBankAccountTransactionMatching = (employerPayRunId: string) => {
    dispatch(incompleteEmployerPayRunsLoading())   

    const url = restConfig.routes.employerPayRun + `/${employerPayRunId}/ignore`

    return fetchClient.post(url)
      .then(response => {
          dispatch(employerPayRunIgnoredWhenTransactionMatching(employerPayRunId))
      })
      .catch(error => {
          console.log('ignoreWhenBankAccountTransactionMatching error:', error)
          dispatch(employerPayRunIgnoredWhenTransactionMatchingFailure(error))
      })
  }

  return {
    setIncompletePayRunsPageIndex,
    setIncompletePayRunsFilter,
    setFrozenPayRunsPageIndex,
    setFrozenPayRunsFilter,
    getIncompleteEmployerPayRuns,
    getIncompletePayRuns,
    getFrozenPayRuns,
    pausePayRun,
    resumePayRun,
    completePayRun,
    includeWhenBankAccountTransactionMatching,
    ignoreWhenBankAccountTransactionMatching
  }
}

export default usePayRunDashboardActions
