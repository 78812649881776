import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PagedListItems, UserListItemModel, UserModel } from '../types'

interface UserState {
    loading: boolean
    userDetails: UserModel | null
    usersOnPage: UserListItemModel[]
    pageIndex: number
    totalCount: number
    pageSize: number
    searchTerm: string | null
    error: Error | string | null
}

const initialState: UserState = {
    loading: false,
    userDetails: null,
    usersOnPage: [],
    totalCount: 0,
    pageIndex: 1,
    pageSize: 20,
    searchTerm: null,
    error: null
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        loading: state => {
            state.loading = true
            state.error = null
        },
        usersFetched: (state, action: PayloadAction<PagedListItems<UserListItemModel>>) => {
            state.loading = false
            state.usersOnPage = action.payload.items
            state.totalCount = action.payload.totalCount
            state.error = null
        },
        usersFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        userDetailsFetched: (state, action: PayloadAction<UserModel>) => {
            state.loading = false
            state.userDetails = action.payload
            state.error = null
        },
        userDetailsFetchFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        userPayoutSettingsUpdated: (state, action: PayloadAction<UserModel>) => {
            state.loading = false
            state.userDetails = action.payload
            state.error = null
        },
        userPayoutSettingsUpdateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        userLockStateSet: (state, action: PayloadAction<UserModel>) => {
            state.loading = false
            state.userDetails = action.payload
            state.error = null
        },
        userLockStateSetFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        userPhoneNumberVerificationStateUpdated: (state, action: PayloadAction<UserModel>) => {
            state.loading = false
            state.userDetails = action.payload
            state.error = null
        },
        userPhoneNumberVerificationStateUpdateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        userPhoneNumberUpdated: (state, action: PayloadAction<UserModel>) => {
            state.loading = false
            state.userDetails = action.payload
            state.error = null
        },
        userPhoneNumberUpdateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        userInternalCommentUpdated: (state, action: PayloadAction<UserModel>) => {
            state.loading = false
            state.userDetails = action.payload
            state.error = null
        },
        userInternalCommentUpdateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
        pageIndexChanged: (state, action: PayloadAction<number>) => {
            state.pageIndex = action.payload
        },
        filterChanged: (state, action: PayloadAction<string>) => {
            state.searchTerm = action.payload
            state.pageIndex = 1
        },
        freePayoutCreated: (state, action: PayloadAction<UserModel>) => {
            state.loading = false
            state.userDetails = action.payload
            state.error = null
        },
        freePayoutCreateFailure: (state, action: PayloadAction<Error | string>) => {
            state.loading = false
            state.error = action.payload
        },
    }
})

export const {
    loading,
    usersFetched,
    usersFetchFailure,
    userDetailsFetched,
    userDetailsFetchFailure,
    userPayoutSettingsUpdated,
    userPayoutSettingsUpdateFailure,
    userLockStateSet,
    userLockStateSetFailure,
    userPhoneNumberVerificationStateUpdated,
    userPhoneNumberVerificationStateUpdateFailure,
    userPhoneNumberUpdated,
    userPhoneNumberUpdateFailure,
    userInternalCommentUpdated,
    userInternalCommentUpdateFailure,
    pageIndexChanged,
    filterChanged,
    freePayoutCreated,
    freePayoutCreateFailure
} = userSlice.actions

export default userSlice.reducer