import { useEffect, useState } from "react"
import Modal from "./Modal"
import TextAreaField from "./TextAreaField"

interface Props {
    title: string
    initialValue?: string
    onSaveClick?: (text: string) => void
    onCancelClick?: () => void
    visible: boolean
}

const TextAreaModal = ({
    title,
    initialValue,
    onSaveClick,
    onCancelClick,
    visible
}: Props) => {
    const [text, setText] = useState(initialValue)

    useEffect(() => {
        if (initialValue)
            setText(initialValue)
    }, [initialValue])

    const handleOnSave = () => {
        onSaveClick && onSaveClick(text ?? '')       
    }

    const handleOnClose = () => {
        onCancelClick && onCancelClick()
    }

    const handleOnChange = (value: string) => {
        setText(value)
    }

    return (
        <Modal
            title={title}
            show={visible}
            okButtonTitle='Save'
            cancelButtonTitle='Cancel'
            onHide={() => handleOnClose()}
            onOkClick={() => handleOnSave()}
            onCancelClick={() => handleOnClose()}
        >
            <TextAreaField
                id='textAreaField'
                key='textAreaField'
                value={text ?? ''}
                onChange={(event) => handleOnChange(event.target.value)}
                label={undefined}
                placeholder=''
              />
        </Modal>
    )
}

export default TextAreaModal